@import 'scss/settings/variable.scss';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

.high_temp_legend {
  @include media-breakpoint-up(xs) {
    display: 'block';
    text-align: center;
  }
  @include media-breakpoint-up(sm) {
    display: 'inline-block';
    text-align: right;
  }
}

.low_temp_legend {
  @include media-breakpoint-up(xs) {
    display: 'block';
    text-align: center;
  }
  @include media-breakpoint-up(sm) {
    display: 'inline-block';
    text-align: left;
  }
}

.flex_items_center{
  display: flex;
  justify-content: center; 
  align-items: center;
  font-size: larger;
  font-weight: initial;
}

.no_data{
  margin-top: 50%;
  justify-content: center;
  display: flex;
  font-weight: 300;
  font-size: large;
}

.risk_meter{
  position: relative;
  margin: auto;
  width: 120px;
  height: auto;
}

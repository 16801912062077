@import '../../../../../scss/settings/variable.scss';

.box {
    background-color: lightgrey;
    cursor: pointer;
    width: 150px;
    height: 150px;
    margin: 20px;
  }

  .box_mobile {
    background-color: lightgrey;
    cursor: pointer;
    width: 120px;
    height: 120px;
    margin: 10px;
  }

.flex-justify-center {
  justify-content: center;
  display: flex;
}
.bold-text{
  font-weight: 700;
}

.deviceOn {
  background-color: #82a1ed !important;
 }

.mouseDown {
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.9s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
 }

 .rcorners {
  border-radius: 25px;
  border: 2px solid #4196D6;
  padding: 10px; 
  padding-left: 50px;
}

.rcorners_paddingLeft_30 {
  border-radius: 25px;
  border: 2px solid #4196D6;
  padding: 10px; 
  padding-left: 35px;
}


.dashboard {
  // background: linear-gradient(#7DAA41, #91AB8A, transparent), url("../shared/img/smartfarming_bg.jpg") ;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
.dashboard__area {
    font-size: 11px;

    .recharts-legend-wrapper {
      bottom: 0 !important;
    }
  }

  .dashboard__card-widget {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .dashboard__total {
    display: flex;
    margin-top: -15px;

    &.dashboard__total--area {
      margin-top: -55px;
    }
  }

  .dashboard_forecast_widgeticon {
    height: 10px;
    width: 10px;
    margin-right: 2px;
    margin-bottom: 4px;
  }

  .dashboard__chart-container {
    flex: 1;
    width: 0;

    @media (min-width: 1200px) {
      & {
        max-width: 180px;
      }
    }
  }

  .dashboard__align_text_center {
    text-align: center;
  }

  .dashboard__health-chart {
    position: relative;
  }

  .dashboard__health-chart-info {
    position: absolute;
    width: 100%;
    top: calc(50% - 55px);
    animation: label 1.5s ease-in;

    p {
      margin: 0;
    }
  }

  .dashboard__health-chart-number {
    font-size: 56px;
    line-height: 60px;

    @include themify($themes) {
      color: themed('colorFitness');
    }
  }

  .dashboard__health-chart-units {
    color: $color-additional;
    margin-top: 5px;
  }
  // .MuiTableCell-alignLeft{
  //   max-width: 32em;
  //   text-overflow: inherit;
  //   overflow: scroll;
  //   }
}
@import '../../../../../scss/settings/variable.scss';

.dashboard__card-widget {
    padding-top: 25px;
    padding-bottom: 25px;
}
.dashboard_icon {
  fill: "#3484ed";
  height: 20px;
  width: 20px;
  margin-right: 3px;
  min-width: 24px;
  margin-top: auto;
}


.print{
    @media print{
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 1000;
        @page {margin: 0;}
        body{margin: 1.6cm;}
        color-adjust: exact !important;
        -webkit-print-color-adjust: exact !important
    }
}

 @media print {
    .tableHeader th {
        // background-color: black !important;
        -webkit-print-color-adjust: exact;
        color: black !important;
    }

    // .infoTable {
    //     tbody > tr:nth-child(2n+1) > td, 

    //     tbody > tr:nth-child(2n+1) > th {
    //         background-color: red;
    //       }
    // }
 }
@import '../../../scss/settings/variable.scss';

.account__btns {
  display: flex;
  width: calc(100% + 10px);
  margin: -10px 0 -20px -10px;

  a {
    margin: 10px 0 20px 10px;
    white-space: nowrap;
  }
}

.account__btn {
  width: 100%;
  margin-right: 0;
}

.form__form-group--forgot {
  margin-bottom: 40px;
}

@media screen and (max-width: 520px) {
  .account__card {
    padding: 35px 30px;
  }
}

@media screen and (max-width: 425px) {
  .account__btns {
    flex-wrap: wrap;
  }
}
